
import {
	INotificationMonitoring,
	isFullNotificationMonitoring,
} from "@common/notificationMonitoring";
import {
	IExtraActivityMonitoring,
	isExtraActivityMonitoring,
} from "@common/extraActivityMonitoring";
import { IStation, IStationOperator } from "@common/station";
import moment from "moment";
import Vue, { PropType } from "vue";
import { utils, writeFile } from "xlsx";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IServerRES } from "@common/server";
import { ServerError } from "@common/errors";
import _ from "lodash";

/**
 *	Interface for the final notification object
 *	@member task - The task of the notification ("Preparare/Pregatire" by `notificationType`, `quantity`, unit by `notificationType` && `ingredient.unit`, `product`/`ingredient`)
 *	@member creationTime - The time when the notification was created
 *	@member lastReactionTime - The time when the notification was opened
 *	@member timeToOpen - The time between the creation and opening of the notification
 *	@member operatorName - The name of the `operator`
 *	@member completed - The status of the notification ("Completed"/"Not Completed"), based on the `producedQuantity` and `quantity`
 *	@member mentions - The mentions of the notification ("Preparat/Pregatit" by `notificationType`, `producedQuantity`, unit by `notificationType` && `ingredient.unit`, `product`/`ingredient`)
 *	@member timeOfPrint - The time when the `product`/`ingredient` was prepared
 *	@member standardTimeOfProduction - The `standard time of production` of the `product`/`ingredient`
 *	@member timeOfProduction - The time between the opening of the notification and the preparation of the `product`/`ingredient`
 *	@member delay - The delay between the `standard time of production` and the time of production
 *	@member timeStatus - The status of the time ("On time"/"Delayed"), based on the delay
 **/
interface FinalNotification {
	task: string;
	creationTime: string;
	lastReactionTime?: string;
	timeToOpen: string;
	operatorName: string;
	completed: string;
	mentions: string;
	timeOfPrint: string;
	standardTimeOfProduction: number | string;
	timeOfProduction: string;
	delay: string;
	timeStatus: string;
	createdBy: string;
}

/**
 *	Interface for the final extra activity object
 *	@member activity - The activity of the extra activity ("Pregatit/Preprat" by `notificationType`, `quantity`, unit by `notificationType`, `product`/`ingredient`)
 *	@member startTime - The time when the extra activity started
 *	@member endTime - The time when the extra activity ended
 *	@member standardTimeOfProduction - The `standard time of production` of the `product`/`ingredient`
 *	@member duration - The duration of the extra activity
 *	@member delay - The delay between the standard time of production and the duration
 *	@member timeStatus - The status of the time ("On time"/"Delayed"), based on the delay
 **/
interface FinalExtraActivity {
	activity: string;
	startTime: string;
	endTime: string;
	operatorName: string;
	standardTimeOfProduction: string;
	duration: string;
	delay: string;
	timeStatus: string;
}

export default Vue.extend({
	name: "OperatorMonitoringDialog",

	props: {
		allOperators: {
			type: Array as PropType<IStationOperator[]>,
			required: true,
		},
		station: Object as PropType<IStation>,
		operator: Object as PropType<IStationOperator>,
	},

	data: () => {
		return {
			axiosSource: axios.CancelToken.source(),
			tableLoading: false as boolean,
			notificationHeaders: [
				{
					text: "Task",
					align: "start",
					sortable: true,
					value: "task",
					width: "250px",
				},
				{ text: "Notified At", value: "creationTime", width: "200px" },
				{
					text: "Opened At",
					value: "lastReactionTime",
					width: "200px",
				},
				{ text: "Time to Open", value: "timeToOpen", width: "150px" },
				{ text: "Operator", value: "operatorName", width: "150px" },
				{ text: "Action Status", value: "completed", width: "150px" },
				{ text: "Action Result", value: "mentions", width: "250px" },
				{
					text: "SW/Ingredient Prepared",
					value: "timeOfPrint",
					width: "200px",
				},
				{
					text: "Standard Time of Production",
					value: "standardTimeOfProduction",
					width: "250px",
				},
				{
					text: "Time of Production",
					value: "timeOfProduction",
					width: "200px",
				},
				{ text: "Delay", value: "delay", width: "150px" },
				{ text: "Time Status", value: "timeStatus", width: "150px" },
				{ text: "Created By", value: "createdBy", width: "150px" },
			],
			extraActivityHeaders: [
				{ text: "Activity", value: "activity", width: "250px" },
				{ text: "Start Time", value: "startTime", width: "200px" },
				{ text: "End Time", value: "endTime", width: "200px" },
				{ text: "Operator", value: "operatorName", width: "150px" },
				{
					text: "Standard Time of Production",
					value: "standardTimeOfProduction",
					width: "250px",
				},
				{ text: "Duration", value: "duration", width: "150px" },
				{ text: "Delay", value: "delay", width: "150px" },
				{ text: "Time Status", value: "timeStatus", width: "150px" },
			],
			filters: {
				dateRange: [
					moment().subtract(29, "days").format("YYYY-MM-DD"),
					moment().format("YYYY-MM-DD"),
				] as string[],
				timezone: moment().format("Z"),
			} as {
				dateRange: string[];
				timezone: string;
			},
			menus: {
				dateRange: false,
			},
			selectedRange: 3,
			loadedNotifications: [] as INotificationMonitoring[],
			loadedExtraActivities: [] as IExtraActivityMonitoring[],
			notifications: [] as INotificationMonitoring[],
			extraActivities: [] as IExtraActivityMonitoring[],
			finalNotifications: [] as FinalNotification[],
			finalExtraActivities: [] as FinalExtraActivity[],
			allOperatorsForCreated: [] as IStationOperator[],
			operatorCreated: {} as IStationOperator,
		};
	},

	mounted: async function () {
		this.tableLoading = true;

		if (this.store.getters.productionStandardTimes.length === 0) {
			await this.store.dispatch.fetchProductionStandardTimes();
		}

		if (this.store.getters.products.length === 0) {
			await this.store.dispatch.fetchProducts();
		}

		if (this.store.getters.ingredients.length === 0) {
			await this.store.dispatch.fetchIngredients();
		}

		await this.fetchData();
	},

	computed: {
		formatTime () {
			return (time: string) =>
				moment.utc(time).tz(moment.tz.guess()).format("HH:mm");
		},
		formatDate () {
			return (time: string) =>
				moment.utc(time).tz(moment.tz.guess()).format("DD/MM/YYYY");
		},
		dateRange (): string {
			if (this.filters.dateRange.length > 0) {
				let dateRange = moment(this.filters.dateRange[0]).format("DD/MM/YYYY");

				if (this.filters.dateRange.length > 1) {
					dateRange += `, ${moment(this.filters.dateRange[1]).format("DD/MM/YYYY")}`;
				}
				return dateRange;
			}
			return "";
		},
	},

	methods: {
		async loadNotifications () {
			this.loadedNotifications = await this.fetchNotificationMonitorings(
				this.station.id,
			);
			this.loadedExtraActivities = await this.fetchExtraActivityMonitorings(
				this.station.id,
			);
		},
		async fetchNotificationMonitorings (
			id: string,
		): Promise<INotificationMonitoring[]> {
			try {
				const url =
					this.operator.id === "-1"
						? `${this.store.getters.serverURL}/notification-monitoring/${id}`
						: `${this.store.getters.serverURL}/notification-monitoring/getByOperatorId/${this.operator.id}`;

				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
					url,
					cancelToken: this.axiosSource.token,
				};

				const res: AxiosResponse<IServerRES<INotificationMonitoring[]>> =
					await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					return res.data.payload;
				}
			} catch (err) {
				console.error(err);
			}

			return [];
		},
		async fetchExtraActivityMonitorings (
			id: string,
		): Promise<IExtraActivityMonitoring[]> {
			try {
				const url =
					this.operator.id === "-1"
						? `${this.store.getters.serverURL}/extra-activity-monitoring/${id}`
						: `${this.store.getters.serverURL}/extra-activity-monitoring/getByOperatorId/${this.operator.id}`;

				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
					url,
					cancelToken: this.axiosSource.token,
				};

				const res: AxiosResponse<IServerRES<IExtraActivityMonitoring[]>> =
					await axios(options);
				if (res.data.err === ServerError.NO_ERROR) {
					return res.data.payload;
				}
			} catch (err) {
				console.error(err);
			}

			return [];
		},
		async fetchData () {
			this.tableLoading = true;

			await this.loadNotifications();

			const startDate = new Date(this.filters.dateRange[0]);
			startDate.setHours(0, 0, 0, 0);
			const endDate = new Date(this.filters.dateRange[1]);
			endDate.setHours(23, 59, 59, 999);

			// filter by date range
			this.notifications = this.loadedNotifications.filter((notification) => {
				if (isFullNotificationMonitoring(notification)) {
					return (
						(moment(notification.creationTime).isSameOrAfter(startDate) &&
							moment(notification.lastReactionTime).isBefore(endDate)) ||
						(moment(notification.lastReactionTime).isSameOrBefore(endDate) &&
							moment(notification.creationTime).isAfter(startDate)) ||
						(moment(notification.creationTime).isBefore(startDate) &&
							moment(notification.lastReactionTime).isAfter(endDate))
					);
				} else {
					return moment(notification.creationTime).isBetween(
						startDate,
						endDate,
					);
				}
			});

			// map to final notification object
			this.finalNotifications = this.notifications.map((notification) => {
				const product = this.store.getters.products.find((product) => {
					return product.id === notification.productId;
				});

				const ingredient = this.store.getters.ingredients.find((ingredient) => {
					return ingredient.id === notification.productId;
				});

				let time: number | string = "";

				// get the standard time of production
				if (product) {
					const standardTime = this.store.getters.productionStandardTimes.find(
						(time) => {
							return time.id === product.id;
						},
					);
					time = standardTime
						? Math.floor(
							standardTime.miseEnPlace +
									standardTime.fixedValue +
									standardTime.variableValue *
										(notification.quantity - standardTime.minimumQuantity),
						)
						: "Not Available";
				} else if (ingredient) {
					const standardTime = this.store.getters.productionStandardTimes.find(
						(time) => {
							return time.id === ingredient.id;
						},
					);
					const unit = ingredient.unit === "kg" ? 100 : 1;
					time = standardTime
						? Math.floor(
							standardTime.fixedValue +
									(standardTime.variableValue *
										(notification.quantity - standardTime.minimumQuantity)) /
										unit,
						)
						: "Not Available";
				}
				const formattedTime: string =
					typeof time === "number"
						? `${String(Math.floor(time / 60)).padStart(2, "0")}:${String(Math.floor(time % 60)).padStart(2, "0")}`
						: time;

				const currentOperatorNotification = (
					this.$props.allOperators as IStationOperator[]
				).find((op) => op.id === notification.operatorId);
				const createdByOperator = (
					this.$props.allOperators as IStationOperator[]
				).find((op) => op.id === notification.createdBy);
				const auxNotification: FinalNotification = {
					task: `${notification.notificationType === 0 ? "Preparare" : "Pregătire"} ${notification.quantity}${product ? " buc" : ingredient ? (ingredient.unit === "kg" ? " g" : ` ${ingredient.unit}`) : ""} ${product ? product.title : ingredient ? ingredient.name : ""}.`,
					creationTime: moment
						.utc(notification.creationTime)
						.tz(moment.tz.guess())
						.format("DD/MM/YYYY HH:mm"),
					lastReactionTime: notification.lastReactionTime
						? moment
							.utc(notification.lastReactionTime)
							.tz(moment.tz.guess())
							.format("DD/MM/YYYY HH:mm")
						: "-",
					timeToOpen: notification.lastReactionTime
						? moment
							.utc(notification.lastReactionTime)
							.diff(moment.utc(notification.creationTime), "minutes") + " min"
						: "-",
					operatorName: currentOperatorNotification
						? currentOperatorNotification.fullName
						: "N/A",
					completed: notification.producedQuantity
						? notification.producedQuantity >= notification.quantity
							? "Completed"
							: "Not Completed"
						: "Not Completed",
					mentions: notification.producedQuantity
						? `${notification.notificationType === 0 ? "Preparat" : "Pregatit"} ${notification.producedQuantity}${product ? "buc" : ingredient ? (ingredient.unit === "kg" ? " g" : ` ${ingredient.unit}`) : ""} ${product ? product.title : ingredient ? ingredient.name : ""}.`
						: "-",
					timeOfPrint: notification.timeOfProduction
						? moment
							.utc(notification.timeOfProduction)
							.tz(moment.tz.guess())
							.format("DD/MM/YYYY HH:mm")
						: "-",
					standardTimeOfProduction: formattedTime,
					timeOfProduction:
						notification.lastReactionTime && notification.timeOfProduction
							? (() => {
								const diffInSeconds = moment
									.utc(notification.timeOfProduction)
									.diff(moment.utc(notification.lastReactionTime), "seconds");
								const minutes = Math.floor(diffInSeconds / 60);
								const seconds = diffInSeconds % 60;
								const mmss = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
								return mmss;
							})()
							: "-",
					delay:
						typeof time === "number"
							? notification.lastReactionTime && notification.timeOfProduction
								? (() => {
									const diffInSeconds =
											moment
												.utc(notification.timeOfProduction)
												.diff(
													moment.utc(notification.lastReactionTime),
													"seconds",
												) - time;
									const minutes = Math.trunc(diffInSeconds / 60);
									const seconds = Math.abs(Math.floor(diffInSeconds % 60));
									const mmss = `${diffInSeconds < 0 && minutes === 0 ? "-" : ""}${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
									return mmss;
								})()
								: "Not Available"
							: "Not Available",
					timeStatus:
						typeof time === "number"
							? notification.lastReactionTime && notification.timeOfProduction
								? time -
										moment
											.utc(notification.timeOfProduction)
											.diff(
												moment.utc(notification.lastReactionTime),
												"seconds",
											) >
									0
									? "On time"
									: "Delayed"
								: "Not Available"
							: "Not Available",
					createdBy: createdByOperator
						? createdByOperator.fullName
						: "Automatic",
				};

				return auxNotification;
			});

			if (this.operatorCreated.fullName !== "All Operators") {
				this.finalNotifications = this.finalNotifications.filter(
					(notification) => {
						return notification.createdBy === this.operatorCreated.fullName;
					},
				);
			} else {
				this.finalNotifications = this.finalNotifications.filter(
					(notification) => {
						return notification.createdBy !== "Automatic";
					},
				);
			}

			// filter by date range
			this.extraActivities = this.loadedExtraActivities.filter(
				(extraActivity) => {
					if (isExtraActivityMonitoring(extraActivity)) {
						return (
							(moment(extraActivity.startTime).isSameOrAfter(startDate) &&
								moment(extraActivity.endTime).isBefore(endDate)) ||
							(moment(extraActivity.endTime).isSameOrBefore(endDate) &&
								moment(extraActivity.startTime).isAfter(startDate)) ||
							(moment(extraActivity.startTime).isBefore(startDate) &&
								moment(extraActivity.endTime).isAfter(endDate))
						);
					} else {
						return moment(extraActivity.startTime).isBetween(
							startDate,
							endDate,
						);
					}
				},
			);

			// map to final extra activity object
			this.finalExtraActivities = this.extraActivities.map((extraActivity) => {
				const product = this.store.getters.products.find((product) => {
					return product.id === extraActivity.productId;
				});

				const ingredient = this.store.getters.ingredients.find((ingredient) => {
					return ingredient.id === extraActivity.productId;
				});

				let time: number | string = "";

				if (product) {
					const standardTime = this.store.getters.productionStandardTimes.find(
						(time) => {
							return time.id === product.id;
						},
					);
					time = standardTime
						? Math.floor(
							standardTime.miseEnPlace +
									standardTime.fixedValue +
									standardTime.variableValue *
										(extraActivity.quantity - standardTime.minimumQuantity),
						)
						: "Not Available";
				} else if (ingredient) {
					const standardTime = this.store.getters.productionStandardTimes.find(
						(time) => {
							return time.id === ingredient.id;
						},
					);

					const unit = ingredient.unit === "kg" ? 100 : 1;
					time = standardTime
						? Math.floor(
							standardTime.fixedValue +
									(standardTime.variableValue *
										(extraActivity.quantity - standardTime.minimumQuantity)) /
										unit,
						)
						: "Not Available";
				}
				const formattedTime: string =
					typeof time === "number"
						? `${String(Math.floor(time / 60)).padStart(2, "0")}:${String(Math.floor(time % 60)).padStart(2, "0")}`
						: time;

				let delay = "";
				if (
					typeof time === "number" &&
					extraActivity.startTime &&
					extraActivity.endTime
				) {
					const diffInSeconds =
						moment
							.utc(extraActivity.endTime)
							.diff(moment.utc(extraActivity.startTime), "seconds") - time;
					const minutes =
						diffInSeconds > 0
							? Math.floor(diffInSeconds / 60)
							: Math.floor((-1 * diffInSeconds) / 60);
					const seconds =
						diffInSeconds > 0
							? Math.floor(diffInSeconds % 60)
							: Math.floor((-1 * diffInSeconds) % 60);

					if (diffInSeconds < 0) {
						delay = "-";
					}

					delay +=
						String(minutes).padStart(2, "0") +
						":" +
						String(seconds).padStart(2, "0");
				} else {
					delay = "Not Available";
				}

				const currentOperatorNotification = (
					this.$props.allOperators as IStationOperator[]
				).find((op) => op.id === extraActivity.operatorId);
				const auxExtraActivity: FinalExtraActivity = {
					activity: `Pregatit ${extraActivity.quantity} ${product ? "buc" : ingredient ? (ingredient.unit === "kg" ? " g" : ` ${ingredient.unit}`) : ""} ${product ? product.title : ingredient ? ingredient.name : ""}.`,
					startTime: moment
						.utc(extraActivity.startTime)
						.tz(moment.tz.guess())
						.format("DD/MM/YYYY HH:mm"),
					endTime: moment
						.utc(extraActivity.endTime)
						.tz(moment.tz.guess())
						.format("DD/MM/YYYY HH:mm"),
					operatorName: currentOperatorNotification
						? currentOperatorNotification.fullName
						: "N/A",
					standardTimeOfProduction: formattedTime,
					duration:
						extraActivity.startTime && extraActivity.endTime
							? (() => {
								const diffInSeconds = moment
									.utc(extraActivity.endTime)
									.diff(moment.utc(extraActivity.startTime), "seconds");
								const minutes = Math.floor(diffInSeconds / 60);
								const seconds = diffInSeconds % 60;
								const mmss = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
								return mmss;
							})()
							: "-",
					delay: delay,
					timeStatus:
						typeof time === "number"
							? moment
								.utc(extraActivity.endTime)
								.diff(moment.utc(extraActivity.startTime), "seconds") -
									time <
								0
								? "On time"
								: "Delayed"
							: "Not Available",
				};

				return auxExtraActivity;
			});

			/**  add a delay after the table is loaded
				because it's too fast and the loading dialog doesn't show up
				and that can be annoying for the user */
			setTimeout(() => {
				this.tableLoading = false;
			}, 500);
		},
		updateDateRange () {
			switch (this.store.state.dashboard.rangePresets[this.selectedRange]) {
				case "Today":
					this.filters.dateRange = [
						moment().format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD"),
					];
					break;
				case "Yesterday":
					this.filters.dateRange = [
						moment().subtract(1, "days").format("YYYY-MM-DD"),
						moment().subtract(1, "days").format("YYYY-MM-DD"),
					];
					break;
				case "Last 7 Days":
					this.filters.dateRange = [
						moment().subtract(6, "days").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD"),
					];
					break;
				case "Last 30 Days":
					this.filters.dateRange = [
						moment().subtract(29, "days").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD"),
					];
					break;
				case "This Month":
					this.filters.dateRange = [
						moment().startOf("month").format("YYYY-MM-DD"),
						moment().format("YYYY-MM-DD"),
					];
					break;
				case "Last Month":
					this.filters.dateRange = [
						moment()
							.subtract(1, "months")
							.startOf("month")
							.format("YYYY-MM-DD"),
						moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD"),
					];
					break;
				default:
					this.filters.dateRange = [];
			}
		},
		sortCustomRange () {
			this.selectedRange =
				this.store.state.dashboard.rangePresets.indexOf("Custom Range");
			this.filters.dateRange.sort((a, b) => moment(a).diff(moment(b)));
		},
		getCompletedColumn (completed: string) {
			if (completed === "Completed") {
				return {
					color: "green--text font-weight-bold",
					text: "Completed",
				};
			} else {
				return {
					color: "red--text font-weight-bold",
					text: "Not Completed",
				};
			}
		},
		getTimeStatusColumn (timeStatus: string) {
			switch (timeStatus) {
				case "On time":
					return {
						color: "green--text font-weight-bold",
						text: "On time",
					};
				case "Delayed":
					return {
						color: "red--text font-weight-bold",
						text: "Delayed",
					};
				default:
					return {
						color: "yellow--text font-weight-bold",
						text: "Not Available",
					};
			}
		},
		generateNotificationsExcel () {
			const notificationData = utils.json_to_sheet(this.finalNotifications);
			utils.sheet_add_aoa(
				notificationData,
				[
					[
						"Task",
						"Notified At",
						"Opened At",
						"Time to Open",
						"Operator",
						"Action Status",
						"Action Result",
						"SW/Ingredient Prepared",
						"Standard Time of Production",
						"Time of Production",
						"Delay",
						"Time Status",
					],
				],
				{ origin: "A1" },
			);
			const extraActivitiesData = utils.json_to_sheet(
				this.finalExtraActivities,
			);
			utils.sheet_add_aoa(
				extraActivitiesData,
				[
					[
						"Activity",
						"Start Time",
						"End Time",
						"Standard Time of Production",
						"Duration",
						"Delay",
						"Time Status",
					],
				],
				{ origin: "A1" },
			);
			const wb = utils.book_new();
			utils.book_append_sheet(wb, notificationData, "Notifications");
			utils.book_append_sheet(wb, extraActivitiesData, "Extra Activities");
			writeFile(wb, "monitoring-report.xlsx");
		},
	},
	watch: {
		async stationId () {
			await this.fetchData();
		},
		async operatorId () {
			await this.fetchData();
		},
		tableLoading: function (newTableLoading) {
			if (!newTableLoading) {
				this.axiosSource.cancel("Request canceled by the user.");
				this.axiosSource = axios.CancelToken.source();
			}
		},
		allOperators () {
			this.allOperatorsForCreated = _.cloneDeep(this.allOperators);

			this.allOperatorsForCreated[0] = {
				id: "-2",
				stationId: "",
				username: "",
				fullName: "Automatic",
			};

			this.allOperatorsForCreated.unshift({
				id: "-1",
				stationId: "",
				username: "",
				fullName: "All Operators",
			});

			this.operatorCreated = this.allOperatorsForCreated[1];
		},
	},
});
