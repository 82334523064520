/**
 * Interface for the notification monitoring
 * 
 * When a user receives a notification, we will monitor his/her reaction time and the production time.
 * 
 * The notification is first added to the database when the user receives it.
 * 
 * After the user clicks on the notification, he is prompted on the production screen to start the production.
 * 
 * When the user prints the label of the finished products, the notification is updated with the rest of the data.
 */
export interface INotificationMonitoring {
    notificationId: string;
    operatorId: string;
    notificationType: number;
    productId: string;
    quantity: number;
    creationTime: Date;
    lastReactionTime?: Date;
    producedQuantity?: number;
    timeOfProduction?: Date;
    createdBy?: string;
}

export interface INotificationMonitoringRES {
    succeeded: boolean;
    notificationMonitoring: INotificationMonitoring;
}

export const EMPTY_NOTIFICATION_MONITORING: INotificationMonitoring = {
    notificationId: "",
    operatorId: "",
    notificationType: 0,
    productId: "",
    quantity: 0,
    creationTime: new Date()
};

/**
 * Checks if the given object is a created NotificationMonitoring.
 */
export function isNotificationMonitoring (obj: any): boolean {
    const isNotificationId = obj.notificationId !== undefined && obj.notificationId !== null;
    const isOperatorId = obj.operatorId !== undefined && obj.operatorId !== null;
    const isNotificationType = obj.notificationType !== undefined && obj.notificationType !== null;
    const isProductId = obj.productId !== undefined && obj.productId !== null;
    const isQuantity = obj.quantity !== undefined && obj.quantity !== null;
    const isCreationTime = obj.creationTime !== undefined && obj.creationTime !== null;

    const ret = isNotificationId && isOperatorId && isNotificationType && isProductId && isQuantity && isCreationTime;

    return ret;
}

export function isClickedNotificationMonitoring (obj: any): boolean {
    const isLastReactionTime = obj.lastReactionTime !== undefined && obj.lastReactionTime !== null;

    const ret = isNotificationMonitoring(obj) && isLastReactionTime;
    
    return ret;
}

/**
 * Checks if the given object is a full NotificationMonitoring.
 */
export function isFullNotificationMonitoring (obj: any): boolean {
    const isLastReactionTime = obj.lastReactionTime !== undefined && obj.lastReactionTime !== null;
    const isProducedQuantity = obj.producedQuantity !== undefined && obj.producedQuantity !== null;
    const isTimeOfProduction = obj.timeOfProduction !== undefined && obj.timeOfProduction !== null;

    const ret = isNotificationMonitoring(obj) && isLastReactionTime && isProducedQuantity && isTimeOfProduction;
    
    return ret;
}
