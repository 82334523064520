import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,[_c(VCard,[_c(VCardTitle,[_c(VCol,{attrs:{"cols":"6"}},[_c(VAutocomplete,{attrs:{"label":"Gas Station","items":_vm.stations,"item-text":"name","return-object":""},model:{value:(_vm.currentStation),callback:function ($$v) {_vm.currentStation=$$v},expression:"currentStation"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VAutocomplete,{attrs:{"label":"Operator","items":_vm.operators,"item-text":"fullName","return-object":""},model:{value:(_vm.currentOperator),callback:function ($$v) {_vm.currentOperator=$$v},expression:"currentOperator"}})],1)],1),_c(VCardText,[_c('operator-monitor',{attrs:{"allOperators":_vm.operators,"station":_vm.currentStation,"operator":_vm.currentOperator}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }