/**
 * ExtraActivityMonitoring
 * 
 * When a user is doing a production action (e.g. cutting, making a sandwich, etc.) he/she can be monitored by a supervisor.
 * 
 * ExtraActivityMonitoring is used when the action is done unrelated to a prediction notification.
 */
export interface IExtraActivityMonitoring {
    activityId: string;
    operatorId: string;
    productId: string;
    quantity: number;
    startTime: Date;
    endTime: Date;
}

export interface IExtraActivityMonitoringRES {
    succeeded: boolean;
    extraActivityMonitoring: IExtraActivityMonitoring;
}

export const EMPTY_EXTRA_ACTIVITY_MONITORING: IExtraActivityMonitoring = {
    activityId: "",
    operatorId: "",
    productId: "",
    quantity: 0,
    startTime: new Date(),
    endTime: new Date()
};

/**
 * Checks if the given object is an ExtraActivityMonitoring.
 */
export function isExtraActivityMonitoring (obj: any): boolean {
    const isActivityId = obj.activityId !== undefined && obj.activityId !== null;
    const isOperatorId = obj.operatorId !== undefined && obj.operatorId !== null;
    const isProductId = obj.productId !== undefined && obj.productId !== null;
    const isQuantity = obj.quantity !== undefined && obj.quantity !== null;
    const isStartTime = obj.startTime !== undefined && obj.startTime !== null;
    const isEndTime = obj.endTime !== undefined && obj.endTime !== null;

    const ret = isActivityId && isOperatorId && isProductId && isQuantity && isStartTime && isEndTime;

    return ret;
}
