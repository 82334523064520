
import Vue from "vue";
import _ from "lodash";

import { IStation, IStationOperator } from "@common/station";
import OperatorMonitoringDialog from "@/components/monitoring/OperatorMonitoringDialog.vue";

export default Vue.extend({
	name: "Monitoring",

	components: {
		"operator-monitor": OperatorMonitoringDialog,
	},

	data: () => {
		return {
			propsLoaded: false as boolean,
			currentStation: {} as IStation,
			stations: [] as IStation[],
			operators: [] as IStationOperator[],
			currentOperator: {} as IStationOperator,
		};
	},

	methods: {

	},

	created: async function () {
		this.store.dispatch.changeAppTitle("Monitoring");

		if (this.store.getters.stations.length === 0) {
			await this.store.dispatch.fetchStations();
		}

		this.stations = _.cloneDeep(this.store.getters.stations) as IStation[];
		this.currentStation = this.stations[0];

		if (this.store.getters.operators.length === 0) {
			await this.store.dispatch.fetchOperators(this.stations);
		}

		const allOperatorsString: IStationOperator = {
			id: "-1",
			stationId: "",
			username: "",
			fullName: "All Operators"
		};

		this.operators = [];
		this.operators.push(allOperatorsString);
		for (const operator of this.store.getters.operators) {
			if (operator.stationId === this.currentStation.id) {
				this.operators.push(operator);
			}
		}

		this.currentOperator = this.operators[0];
	},

	watch: {
		currentStation: function (newStation: IStation) {
			const allOperators: IStationOperator = {
				id: "-1",
				stationId: "",
				username: "",
				fullName: "All Operators"
			};

			this.operators = [];
			this.operators.push(allOperators);
			for (const operator of this.store.getters.operators) {
				if (operator.stationId === newStation.id) {
					this.operators.push(operator);
				}
			}
			this.currentOperator = this.operators[0];
		},
	}

});
